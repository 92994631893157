<template>
  <div class="pageWrapper">
    <div class="ordersWrapper">
      <div class="topbar">
        <H2>Archived Orders</H2>
      </div>
      <SearchInput placeholder="Search" v-model="searchString" />
      <table class="standardTable">
        <br />
        <tr class="standardTableHeader">
          <th>Order</th>
          <th v-if="!isMobile">Archive time</th>
          <th>Customer</th>
          <th v-if="!isMobile">Delivery method</th>
          <!-- <th>Total</th> -->
          <th v-if="!isMobile">Store</th>
          <th></th>
        </tr>
        <LoadingRowTable :dataList="orders" :columnsAmount="5">
          <OrderItemArchived
            :key="i"
            :order="order"
            v-for="(order, i) in orders"
            :removeOrder="removeOrder"
          />
        </LoadingRowTable>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

import OrderItemArchived from '@/components/orders/OrderItemArchived'
import LoadingRowTable from '@/components/LoadingRowTable'
import SearchInput from '@/layout/SearchInput'
import H2 from '@/layout/typography/H2'

export default {
  components: {
    OrderItemArchived,
    LoadingRowTable,
    SearchInput,
    H2,
  },
  data() {
    return {
      orders: undefined,
      searchString: '',
      amountOrdersToShow: 15,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
    this.getOrders()
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),
    filteredOrders() {
      if(this.orders) {
        return this.orders.slice(0, this.amountOrdersToShow)
      } else {
        return undefined
      }
    },
  },
  watch: {
    searchString() {
      this.getOrders()
    },
  },
  methods: {
    removeOrder(data) {
      let index = this.orders.findIndex((order) => order.orderId === data)
      this.orders.splice(index, 1)
    },
    gotoView(page) {
      this.$router.push({ name: page })
    },
    getOrders() {
      axios.post('orders/getOrders', {
        companyId: this.$store.state.selectedUser.companyId,
        archived: 1,
        confirmed: 1,
        searchString: this.searchString,
      })
        .then(res => {
          this.orders = res.data
        })
    },
    handleScroll(e) {
      if (
        window.innerHeight + window.scrollY + 300 >=
        document.body.offsetHeight
      ) {
        this.amountOrdersToShow += 15
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import url('../../assets/css/table.css');

.standardTable {
  table-layout: auto;
}

.ordersWrapper .topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ordersWrapper .topbar button {
  display: inline-block;
  height: 100%;
  padding: 0 20px;
  margin-left: 15px;
  border: 1px solid;
  background: #999;
  border-radius: 3px;
}

.title {
  font-family: 'Avenir';
  font-size: 20pt;
}
</style>