<template>
  <fragment>
    <tr class="tableRow">
      <td class="ocrTd">
        <span
          style="top: -2px; position: relative"
          @click="toggleOrder(order.ocr)"
          >#{{ order.ocr }}</span
        >
      </td>
      <td v-if="!isMobile">
        {{ formatDateWithMonthNameAndTime(new Date(order.archivedTime)) }}
      </td>
      <td class="customerTd" @click="openCustomer(order.customerId)">
        <span v-if="order.customerId">{{ customerName }}</span>
        <span v-else-if="order.customerId == null">In shop</span>
        <span v-else>{{ order.name }}</span>
        &nbsp;<i v-if="hasModule('customers') && order.customerId != null" class="fas fa-external-link-alt"></i>
      </td>
      <td v-if="!isMobile">{{ unslug(order.deliveryMethod) }}</td>
      <!-- <td>{{ order.totalPrice }} SEK</td> -->
      <td v-if="!isMobile && deliveryMethodSelect != 'hemleverans'">
        {{ order.locationName }}
      </td>
      <td class="buttonsTd">
        <i @click="toggleOption(order.orderId)" class="fas fa-ellipsis-v"></i>
        <div v-if="isHidden" class="optionMenu">
          <p @click="unarchiveOrder(order.orderId)">Unarchive</p>
        </div>
      </td>
    </tr>
    <Loader v-if="!order" :color="'#444'" />
    <tr class="orderInfo">
      <td colspan="10">
        <div class="orderScrollWrapper" :style="toggleOrderStyle">
          <OpenOrder :order="order" />
        </div>
      </td>
    </tr>
  </fragment>
</template>

<script>
import StatusSelector from '../StatusSelector'
import OpenOrder from '../../components/orders/OpenOrder'
import Loader from '../../components/Loader'
import { mapGetters } from 'vuex'
import ModuleService from './../../services/moduleService'
import { formatDateWithMonthNameAndTime } from '../../globalFunctions/date'

export default {
  components: {
    StatusSelector,
    OpenOrder,
    Loader,
  },
  data() {
    return {
      isHidden: false,
      isOpen: false,
    }
  },
  props: {
    order: Object,
    removeOrder: Function,
    deliveryMethodSelect: String,
    selectedOrders: Array,
  },
  computed: {
    customerName() {
      if (this.isMobile && this.order.name) {
        return this.order.name.charAt(0).capitalizeFirstLetter()
      } else {
        return this.order.name.capitalizeFirstLetter()
      }
    },
    toggleOrderStyle() {
      let expandHeight
      if (this.order) {
        if (window.innerWidth < 700) {
          expandHeight = 55 * this.order.products.length + 120
          if (this.order.status === 0 || this.order.status === 1) {
            expandHeight += 50
          }
        } else {
          if (this.order.products) {
            expandHeight = 55 * this.order.products.length + 280
          }
        }

        if (this.isOpen) {
          return { height: expandHeight + 'px' }
        } else {
          return { height: '0px' }
        }
      }
    },
    ...mapGetters({
      isMobile: 'isMobile',
    }),
  },
  methods: {
    formatDateWithMonthNameAndTime,
    hasModule(name) {
      return ModuleService.hasModule(name)
    },
    unarchiveOrder(orderId) {
      this.removeOrder(orderId)
      this.closeOption()
      this.$socket.emit('unarchiveOrder', orderId)
    },
    toggleOption(data) {
      this.isHidden = !this.isHidden
    },
    closeOption() {
      if (this.isHidden) {
        this.isHidden = false
      }
    },
    updateOrderStatus(ocr, newStatus) {
      this.$socket.emit('updateOrderStatus', { ocr, newStatus })
    },
    unslug(string) {

      return (string.charAt(0).toUpperCase() + string.slice(1)).replace(
        '_',
        ' '
      )
    },
    toggleOrder() {
      if (this.isOpen) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    openCustomer(customerId) {
      if (this.hasModule('customers')) {
        this.$router.push({ name: 'OpenCustomer', params: { customerId } })
      } else {
        this.toggleOrder()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.orderScrollWrapper {
  transform-origin: top;
  transition: 0.5s ease-in-out;
  background: white;
  display: block;
  overflow: hidden;
  position: relative;
  height: auto;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -2px rgba(189, 189, 189, 1);
}

.tableRow {
  height: 0;
  position: relative;
  transition: 0.2s;
}

.optionMenuIcon {
  font-size: 12pt;
  display: block;
  margin: 0 !important;
}

.optionMenu {
  position: absolute;
  background: white;
  border-radius: 3px;
  border: 1px solid #eeee;
  right: 100px;
  z-index: 10;
  overflow: hidden;
}

.optionMenu p {
  text-align: left;
  font-size: 9pt;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0;
  border-bottom: 1px solid #eaeaea;
  justify-content: space-between;
  display: flex;
  width: 100px;
  font-weight: lighter;
}

.optionMenu p:hover {
  background: #eaeaea;
}

.optionMenu p:last-child {
  border-bottom: none;
}

.ocrTd {
  color: #4c6ba2;
  cursor: pointer;
}

.buttonsTd {
  text-align: center;
  padding-right: 10px;
}

.buttonsTd i {
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  color: #666;
}

.customerTd {
  cursor: pointer;
}

.statusTd {
  text-align: right;
  width: 10px;
}

.fa-archive {
  cursor: pointer;
}

.fa-archive:hover {
  color: #444;
}

.hoverInfoBox {
  position: absolute;
  display: none;
  font-weight: lighter;
  background: #eaeaea;
  border: 1px solid;
  border-radius: 5px;
  padding: 2px 5px;
}
</style>